<template>
<!--  <router-link-->
<!--    v-for="item in banners"-->
<!--    :key="item.id"-->
<!--    :href="item.url"-->
<!--    :to="{ name: 'catalog-list', params: { category: item.url.detail } }"-->
<!--    :class="classItem"-->
<!--  >-->
<!--          <span class="catalog-link__icon">-->
<!--            <img :src="item.img" alt=""/>-->
<!--          </span>-->
<!--    <span class="catalog-link__text">{{ item.name }}</span>-->
<!--    <img :src="item.picture"-->
<!--         :width="item.pictureSettings.width"-->
<!--         :height="item.pictureSettings.height"-->
<!--         :alt="item.name" />-->
<!--  </router-link>-->
  <a
    :href="banner.url.detail"
    :class="classItem"
    v-for="banner in banners"
    :key="banner.id"
  >
    <img :src="banner.picture"
         :alt="banner.name" />
  </a>
</template>

<script>
export default {
  props: {
    classItem: {
      type: String,
      required: false,
    },
    banners: {
      type: Array,
      required: true,
    },
  },

  computed: {
    bannersFiltered() {
      return this.banners.filter((item) => item.url.component !== 'other');
    },
  },
};
</script>
