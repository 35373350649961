<template>
  <div class="catalog-title__wrapper">
    <div class="container">
      <div class="catalog-title">
        <span class="title" :style="{fontSize: fontSizeName + 'px'}">{{ nameSection }}</span>
      </div>
      <div class="catalog-title">
        <div class="catalog-sorts">
          <div :class="['catalog-sort', sortName.active ? 'active' : '']" @click="sort(sortName)">
            <div class="catalog-sort__name">По алфавиту</div>
            <div class="catalog-sort__icon">
              <svg class="icon icon-down ">
                <use xlink:href="#down"></use>
              </svg>
            </div>
          </div>
          <div :class="['catalog-sort', sortPrice.active ? 'active' : '']" @click="sort(sortPrice)">
            <div class="catalog-sort__name">По цене</div>
            <div class="catalog-sort__icon">
              <svg class="icon icon-down ">
                <use xlink:href="#down"></use>
              </svg>
            </div>
          </div>
        </div>
        <button class="btn" @click.prevent="isShowFilter = true">
          <svg class="icon icon-filter ">
            <use xlink:href="#filter"></use>
          </svg>
        </button>
      </div>
    </div>
  </div>

  <div class="catalog-cards__wrapper" v-show="!isShowFilter">
    <div class="container">
      <div class="catalog-cards">
        <div class="section-catalog" v-if="innerBanner && innerBanner.length > 0">
          <div class="catalog-banners">
            <Suggestions :classItem="'catalog-banner'" :banners="innerBanner"/>
          </div>
        </div>
        <div class="catalog-card" v-for="product in products" :key="product.id">
          <Product :product="product" :classItem="'product-card--big'" />
        </div>
        <img
          src="/img/hourglass.svg"
          class="catalog-preloader"
          v-if="this.isPagination"
        />
      </div>
    </div>
  </div>

  <div class="catalog-filters" :class="{'catalog-filters--active' : isShowFilter}">
    <Filter @close="isShowFilter = false" />
  </div>
</template>

<script>
import Product from "@/components/cards/product";
import Filter from "@/views/filter/Filter";
import Suggestions from "../../components/cards/suggestions.vue";

import store from "@/store/index.js";

export default {
  components: {
    Suggestions,
    Product,
    Filter,
  },

  beforeRouteEnter(to, from, next) {
    store.dispatch("CATALOG_FETCH_BANNERS")
    store
      .dispatch("CATALOG_FETCH_PRODUCTS_LIST", {
        'category': to.params.category,
        'page': to.params.page ?? 1,
        'query': to.query,
      })
      .then(() => {
        next();
      });
  },

  data() {
    return {
      fontSizeName: 24,
      page: parseInt(this.$route.params.page),
      sortName: {
        type: 'name',
        order: 'asc',
        active: false,
      },
      sortPrice: {
        type: 'price',
        order: 'asc',
        active: false,
      },
      currentSort: {},
      isLoad: false,
      isShowFilter: false,
    };
  },

  mounted() {
    const cards = document.querySelector(".catalog-cards");

    cards.addEventListener("scroll", () => {
      const top = cards.scrollTop + cards.clientHeight;
      const bottom = cards.scrollHeight;

      if (top + 100 >= bottom && !this.isLoad) {
        this.isLoad = true;
        this.loadProducts();
      }
    });

    if (this.nameSection.length > 22)
      this.fontSizeName = 18;
  },

  computed: {
    innerBanner() {
      return this.$store.state.catalog.innerBanner;
    },
    nameSection() {
      return this.$store.state.catalog.nameSection;
    },

    products() {
      return this.$store.state.catalog.products;
    },

    isPagination() {
      if (this.$store.state.catalog.pagination)
        return this.$store.state.catalog.pagination.paginate.length > this.page;
      else
        return false;
    },
  },

  methods: {
    loadProducts() {
      if (this.isPagination)
        this.$store
          .dispatch("CATALOG_PAGINATION", {
            'category': this.$route.params.category,
            'page': this.page += 1,
            'sort': {
              'type': this.currentSort.type,
              'order': this.currentSort.order,
            },
          })
          .then(() => {
            this.isLoad = false;
          });
    },

    sort(currentSort) {
      this.$store
        .dispatch("CATALOG_SORT", {
          'category': this.$route.params.category,
          'page': this.page = 1,
          'sort': {
            'type': currentSort.type,
            'order': currentSort.order,
          },
        });

      this.changeSort(currentSort);
    },

    changeSort(currentSort) {
      this.currentSort = {
        'type': currentSort.type,
        'order': currentSort.order,
      };

      if (currentSort.type === 'name') {
        this.sortName.order = this.sortName.order === 'asc' ? 'desc' : 'asc';
        this.sortName.active = this.sortName.active !== true;

        this.sortPrice.order = 'asc';
        this.sortPrice.active = false;
      }

      if (currentSort.type === 'price') {
        this.sortPrice.order = this.sortPrice.order === 'asc' ? 'desc' : 'asc';
        this.sortPrice.active = this.sortPrice.active !== true;

        this.sortName.order = 'asc';
        this.sortName.active = false;
      }
    },
  },
};
</script>
